import * as React from "react"

import Navigation from "../components/navigation"
import Footer from "../components/footer"
import Seo from "../components/seo"

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const TermsLayout = ({ location, children }) => {
	const rootPath = `${__PATH_PREFIX__}/`
	const isRootPath = location.pathname === rootPath
	const navigation = [
		{ name: 'Customer terms of service', effective: '1st June 2022', href: '/terms' },
		{ name: 'User terms of service', effective: '1st June 2022', href: '/terms-user' },
		{ name: 'Acceptable use policy', effective: '1st June 2022', href: '/terms-acceptable-use' },
		{ name: 'Subprocessors', effective: '1st June 2022', href: '/terms-subprocessors' },
		{ name: 'Security practices', effective: '1st June 2022', href: '/terms-security-practices' }
	]

	// use path to select navigation item
	let currentNavIndex
	if (location.pathname.startsWith('/terms-user')) currentNavIndex = 1;
	else if (location.pathname.startsWith('/terms-acceptable-use')) currentNavIndex = 2;
	else if (location.pathname.startsWith('/terms-subprocessors')) currentNavIndex = 3;
	else if (location.pathname.startsWith('/terms-security-practices')) currentNavIndex = 4;
	else if (location.pathname.startsWith('/terms')) currentNavIndex = 0;
	else currentNavIndex = 0;

	return (
		<div data-is-root-path={isRootPath} className="bg-gray-50">
			<Seo title={navigation[currentNavIndex].name} description={navigation[currentNavIndex].name} />

			<Navigation />

			<div className="relative py-8">
				<div className="w-full max-w-7xl mx-auto lg:px-8 space-y-12">
					<header>
						<h1 className="text-5xl text-lg font-extrabold">{navigation[currentNavIndex].name}</h1>
						<p className="text-md mt-2 text-gray-500">Effective: {navigation[currentNavIndex].effective}</p>
					</header>

					<div className="flex-grow lg:flex lg:space-x-8">
						<nav aria-label="Sidebar" className="space-y-1 w-64">
							{
								navigation.map((item, index) => (
									<a key={item.name} href={item.href} className={classNames(currentNavIndex === index ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-3 py-2 text-sm font-medium rounded-md')} aria-current={currentNavIndex === index ? 'page' : undefined} >
										<span className="truncate">{item.name}</span>
									</a>
								))
							}
						</nav>

						<div className="lg:min-w-0 lg:flex-1 h-full">
							<main>
								<div className="prose prose-lg text-justify text-gray-800 max-w-none">
									{children}
								</div>
							</main>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default TermsLayout
