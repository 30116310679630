import * as React from "react"
import { Link } from "gatsby"
import TermsLayout from "../templates/terms-layout"

const Terms = ({ location }) => {
	return (
		<TermsLayout location={location}>
			<p>These customer terms of service (the <b>‘Customer Terms’</b>) describe your rights and responsibilities when using our online video management platform (the <b>‘Services’</b>). Please read them carefully. If you are a Customer (defined below), these Customer Terms govern your access to and use of our Services. If you are being invited to a workspace set up by a Customer, the <Link to="/terms-user">user terms of service</Link> (the <b>‘User Terms’</b>) govern your access to and use of the Services. We are grateful that you’re here.</p>
			<h2>First things first</h2>
			<p>These ‘Customer Terms’ form part of a binding ‘Contract’</p>
			<p>These Customer Terms (or, if applicable, your written agreement with us or our affiliate(s) governing the applicable Order Form(s)) and any Order Form(s) (defined below) together form a binding ‘Contract’ between the Customer and us. If any terms in a Customer-Specific Supplement apply to the Customer (e.g. if the Customer is a US government entity), those terms are also incorporated herein by reference and form part of the Contract. ‘We’, ‘our’ and ‘us’ refer to the applicable Exploo entity in the section entitled ‘Which Exploo entity is the Customer contracting with?’ below.</p>
			<p>Your agreement on behalf of the ‘Customer’</p>
			<p>If you purchase subscription(s), create a workspace (i.e. a digital space where a group of users may access the Services), invite users to that workspace, or use or allow use of that workspace after being notified of a change to these Customer Terms, you acknowledge your understanding of the then-current Contract and agree to the Contract on behalf of the Customer. Please make sure that you have the necessary authority to enter into the Contract on behalf of the Customer before proceeding.</p>
			<h2>Customer choices and instructions</h2>
			<p>Who is the ‘Customer’? (Hint: there can be only one)</p>
			<p>The ‘Customer’ is the organisation that you represent in agreeing to the Contract. If your workspace is being set up by someone who is not formally affiliated with an organisation, the Customer is the individual creating the workspace. For example, if you signed up using a personal email address and invited a couple of friends to work on a new start-up idea, but haven't formed a company yet, you are the Customer.</p>
			<p>Signing up using a corporate email domain</p>
			<p>If you signed up for a subscription using your corporate email domain, your organisation is the Customer, and the Customer can modify and reassign roles on your workspace (including your role) and otherwise exercise its rights under the Contract. If the Customer elects to replace you as the representative with ultimate authority for the workspace, we will provide you with notice following such election and you agree to take any actions reasonably requested by us or the Customer to facilitate the transfer of authority to a new representative of the Customer.</p>
			<p>What this means for the Customer – and for us</p>
			<p>Individuals authorised by the Customer to access the Services (an <b>‘Authorised User’</b>) may submit content or information to the Services, such as messages or files (<b>‘Customer Data’</b>), and the Customer may exclusively provide us with instructions on what to do with it. For example, the Customer may provision or de-provision access to the Services, enable or disable third-party integrations, manage permissions, retention and export settings, transfer or assign workspaces, share channels, or consolidate workspaces or channels with other workspaces or channels.</p>
			<p>The Customer will (a) inform Authorised Users of all Customer policies and practices that are relevant to their use of the Services and of any settings that may affect the processing of Customer Data, and (b) ensure that the transfer and processing of Customer Data under the Contract are lawful.</p>
			<p>Ordering subscriptions</p>
			<p>A subscription allows an Authorised User to access the Services. No matter what role they are in, a subscription is required for each Authorised User. A subscription may be procured through the Services interface, or in some cases, via an order form entered into between the Customer and us (each, an ‘Order Form’). Each Authorised User must agree to the <Link to="/terms-user">User Terms</Link> to activate their subscription. Subscriptions commence when we make them available to the Customer and continue for the term specified in the Services ‘checkout’ interface or in the Order Form, as applicable. Each subscription is for a single Authorised User for a specified term and is personal to that Authorised User. We sometimes enter into other kinds of ordering arrangements, but that would need to be spelled out and agreed to in an Order Form. During an active subscription term, adding more subscriptions is fairly easy. Unless the Order Form says otherwise, the Customer may purchase more subscriptions at the same price stated in the Order Form, and all will terminate on the same date.</p>
			<p>Purchasing decisions</p>
			<p>We may share information about our future product subscriptions, because we like transparency. Our public statements about those product subscriptions are an expression of intent, but do not rely on them when making a purchase. If the Customer decides to buy our Services, that decision should be based on the functionality or features that we have made available today and not on the delivery of any future functionality or features.</p>
			<p>Choosing to be a beta tester</p>
			<p>Occasionally, we look for beta testers to help us test our new features. These features will be identified as ‘beta’ or ‘pre-release’, or words or phrases with similar meanings (each, a <b>‘Beta Product’</b>). Beta Products may not be ready for prime time, so they are made available ‘as is’, and any warranties or contractual commitments that we make for other Services do not apply. Should the Customer encounter any faults with our Beta Products, we would love to hear about them; our primary reason for running any beta programmes is to iron out issues before making a new feature widely available.</p>
			<p>We welcome feedback</p>
			<p>The more suggestions our customers make, the better the Services become. If the Customer sends us any feedback or suggestions regarding the Services, there is a chance that we will use it, so the Customer grants us (for itself and all of its Authorised Users and other Customer personnel) an unlimited, irrevocable, perpetual, sublicensable, transferable, royalty-free licence to use any such feedback or suggestions for any purpose without any obligation or compensation to the Customer, any Authorised User or other Customer personnel. If we choose not to implement the suggestion, please don’t take it personally. We appreciate it nonetheless.</p>
			<p>Privacy policy</p>
			<p>Please review our <Link to="/privacy">Privacy policy</Link> for more information on how we collect and use data relating to the use and performance of our websites and products.</p>
			<h2>Customer and Authorised Users</h2>
			<p>Use of the Services</p>
			<p>The Customer must comply with the Contract and ensure that its Authorised Users comply with the Contract and the <Link to="/terms-user">User Terms</Link>. We may review conduct for compliance purposes, but we have no obligation to do so. We aren't responsible for the content of any Customer Data or the way that the Customer or its Authorised Users choose to use the Services to store or process any Customer Data. The Services are not intended for and should not be used by anyone under the age of 16. The Customer must ensure that all Authorised Users are over 16 years of age. The Customer is solely responsible for providing high-speed Internet service for itself and its Authorised Users to access and use the Services.</p>
			<p>Our removal rights</p>
			<p>If we believe that there is a violation of the Contract that can simply be remedied by the Customer’s removal of certain Customer Data, we will, in most cases, ask the Customer to take direct action rather than intervene. However, we may directly step in and take what we determine to be appropriate action if the Customer does not take appropriate action, or if we believe that there is a credible risk of harm to us, the Services, Authorised Users or any third parties.</p>
			<h2>Payment obligations</h2>
			<p>Payment terms</p>
			<p>For Customers that purchase our Services, fees are specified at the Services interface ‘checkout’ and in the Order Form(s) – and must be paid in advance. Payment obligations are non-cancellable and, except as expressly stated in the Contract, fees paid are non-refundable. For clarity, in the event that the Customer downgrades any subscriptions from a paid subscription to a free subscription, the Customer will remain responsible for any unpaid fees under the paid subscription, and Services under the paid subscription will be deemed fully performed and delivered upon expiration of the term of the initial paid subscription. If we agree to invoice the Customer via email, full payment must be received within thirty (30) days from the invoice date. Fees are stated exclusive of any taxes, levies, duties or similar governmental assessments of any nature, including, for example, value-added, sales, use or withholding taxes, assessable by any jurisdiction (collectively, <b>‘Taxes’</b>). The Customer will be responsible for paying all Taxes associated with its purchases, except for those taxes based on our net income. Should any payment for the Services be subject to withholding tax by any government, the Customer will reimburse us for such withholding tax.</p>
			<p>Downgrade for non-payment</p>
			<p>If any fees owed to us by the Customer (excluding amounts disputed reasonably and in good faith) are thirty (30) days or more overdue, we may, without limiting our other rights and remedies, downgrade any fee-based Services to free subscriptions until those amounts are paid in full, so long as we have given the Customer ten (10) or more days’ prior notice that its account is overdue. Notwithstanding the second paragraph of the ‘Providing the Services’ section below, the Customer acknowledges and agrees that a downgrade will result in a decrease in certain features and functionality and potential loss of access to Customer Data.</p>
			<h2>Our responsibilities</h2>
			<p>Providing the Services</p>
			<p>The Customer isn’t the only one with responsibilities: we have some, too. We will (a) make the Services available to the Customer and its Authorised Users as described in the Contract, and (b) not use or process Customer Data for any purpose without the Customer’s prior written instructions; provided, however, that ‘prior written instructions’ will be deemed to include use of the Services by Authorised Users and any processing related to such use or otherwise necessary for the performance of the Contract.</p>
			<p>We will not materially decrease the functionality of a Service during a subscription term. For any breach of a warranty in this section, the Customer’s exclusive remedies are those described in the sections titled ‘Termination for cause’ and ‘Effect of termination’.</p>
			<p>Keeping the Services available</p>
			<p>For all Service subscriptions, we will use commercially reasonable efforts to make the Services available 24 hours a day, seven days a week, excluding planned downtime. We expect planned downtime to be infrequent, but will endeavour to provide the Customer with advance notice (e.g. through the Services) if we think that it may exceed five (5) continuous minutes.</p>
			<p>Protecting Customer Data</p>
			<p>The protection of Customer Data is a top priority for us, so we will maintain administrative, physical and technical safeguards at a level not materially less protective than as described in our <Link to="/terms-security-practices">security practices</Link> page. Those safeguards will include measures for preventing unauthorised access, use, modification, deletion and disclosure of Customer Data by our personnel. Before sharing Customer Data with any of our third-party service providers, we will ensure that the third party maintains, at a minimum, reasonable data practices for maintaining the confidentiality and security of Customer Data and preventing unauthorised access. The Customer (not us) bears sole responsibility for adequate security, protection and backup of Customer Data when it is in the Customer’s or its representatives’ or agents’ possession or control. We are not responsible for what the Customer’s Authorised Users do with Customer Data. That is the Customer’s responsibility.</p>
			<p>The Exploo Extended Family</p>
			<p>We may leverage our employees, those of our corporate affiliates and third-party contractors (the <b>‘Exploo Extended Family’</b>) in exercising our rights and performing our obligations under the Contract. We will be responsible for the Exploo Extended Family’s compliance with our obligations under the Contract.</p>
			<h2>Ownership and proprietary rights</h2>
			<p>What’s yours is yours…</p>
			<p>As between us on the one hand, and the Customer and any Authorised Users on the other, the Customer will own all Customer Data. Subject to the terms and conditions of the Contract, the Customer (for itself and all of its Authorised Users) grants us and the Exploo Extended Family a worldwide, non-exclusive, limited-term licence to access, use, process, copy, distribute, perform, export and display Customer Data, and any non-Exploo products created by or for the Customer, only as reasonably necessary (a) to provide, maintain and update the Services; (b) to prevent or address service, security, support or technical issues; (c) as required by law; and (d) as expressly permitted in writing by the Customer. The Customer represents and warrants that it has secured all rights in and to Customer Data from its Authorised Users as may be necessary to grant this licence.</p>
			<p>And what’s ours is ours</p>
			<p>We own and will continue to own our Services, including all related intellectual property rights. We may make software components available, via app stores or other channels, as part of the Services. We grant to the Customer a non-sublicensable, non-transferable, non-exclusive, limited licence for the Customer and its Authorised Users to use the object code version of these components, but solely as necessary to use the Services and in accordance with the Contract and the <Link to="/terms-user">User Terms</Link>. All of our rights not expressly granted by this licence are hereby retained.</p>
			<h2>Term and termination</h2>
			<p>Contract term</p>
			<p>As further described below, a free subscription continues until terminated, while a paid subscription has a term that may expire or be terminated. The Contract remains effective until all subscriptions ordered under the Contract have expired or been terminated, or the Contract itself terminates. Termination of the Contract will terminate all subscriptions and all Order Forms.</p>
			<p>Auto-renewal</p>
			<p>Unless an Order Form says something different, (a) all subscriptions automatically renew (without the need to go through the ‘checkout’ on the Services interface or execute a renewal Order Form) for additional periods equal to one (1) year or the preceding term, whichever is shorter; and (b) the per-unit pricing during any automatic renewal term will remain the same as it was during the term immediately prior. Either party can give the other notice of non-renewal at least thirty (30) days before the end of a subscription term to stop the subscriptions from automatically renewing.</p>
			<p>Termination for cause</p>
			<p>We or the Customer may terminate the Contract on notice to the other party if the other party materially breaches the Contract and such breach is not cured within thirty (30) days after the non-breaching party provides notice of the breach. The Customer is responsible for its Authorised Users, including for any breaches of this Contract caused by its Authorised Users. We may terminate the Contract immediately on notice to the Customer if we reasonably believe that the Services are being used by the Customer or its Authorised Users in violation of applicable law.</p>
			<p>Termination without cause</p>
			<p>The Customer may terminate its free subscriptions immediately without cause. We may also terminate the Customer’s free subscriptions without cause, but we will provide the Customer with thirty (30) days’ prior written notice.</p>
			<p>Effect of termination</p>
			<p>Upon any termination for cause by the Customer, we will refund the Customer any prepaid fees covering the remainder of the term of all subscriptions after the effective date of termination. Upon any termination for cause by us, the Customer will pay any unpaid fees covering the remainder of the term of those subscriptions after the effective date of termination. In no event will any termination relieve the Customer of the obligation to pay any fees payable to us for the period prior to the effective date of termination.</p>
			<p>Data portability and deletion</p>
			<p>We are custodians of Customer Data. During the term of a workspace’s subscriptions, the Customer will be permitted to export or share certain Customer Data from the Services; provided, however, that because we have different products with varying features and the Customer has different retention options, the Customer acknowledges and agrees that the ability to export or share Customer Data may be limited or unavailable depending on the type of Services subscription in effect and the data retention, sharing or invitation settings enabled. Following termination or expiration of a workspace’s subscriptions, we will have no obligation to maintain or provide any Customer Data and may thereafter, unless legally prohibited, delete all Customer Data in our systems or otherwise in our possession or under our control. Please review our <Link to="/terms-security-practices">security practices</Link> page for more information on how the Customer itself can initiate deletion.</p>
			<h2>Representations, disclaimer of warranties</h2>
			<p>The Customer represents and warrants that it has validly entered into the Contract and has the legal power to do so. The Customer further represents and warrants that it is responsible for the conduct of its Authorised Users and their compliance with the terms of this Contract and the <Link to="/terms-user">User Terms</Link>.</p>
			<p>EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, THE SERVICES AND ALL RELATED COMPONENTS AND INFORMATION ARE PROVIDED ON AN ‘AS IS’ AND ‘AS AVAILABLE’ BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. THE CUSTOMER ACKNOWLEDGES THAT WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE.</p>
			<h2>Limitation of liability</h2>
			<p>OTHER THAN IN CONNECTION WITH A PARTY’S INDEMNIFICATION OBLIGATIONS HEREUNDER, IN NO EVENT WILL EITHER THE CUSTOMER’S OR THE EXPLOO EXTENDED FAMILY’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THE CONTRACT OR THE <Link to="/terms-user">USER TERMS</Link> (WHETHER IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY) EXCEED THE TOTAL AMOUNT PAID BY THE CUSTOMER HEREUNDER IN THE TWELVE (12) MONTHS PRECEDING THE LAST EVENT GIVING RISE TO LIABILITY. THE FOREGOING WILL NOT LIMIT THE CUSTOMER’S PAYMENT OBLIGATIONS UNDER THE ‘PAYMENT TERMS’ SECTION ABOVE.</p>
			<p>IN NO EVENT WILL EITHER THE CUSTOMER OR ANY MEMBER OF THE EXPLOO EXTENDED FAMILY HAVE ANY LIABILITY TO THE OTHER PARTY OR TO ANY THIRD PARTY FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.</p>
			<p>The Customer is responsible for all login credentials, including usernames and passwords, for administrator accounts as well the accounts of your Authorised Users. We will not be responsible for any damages, losses or liability to the Customer, Authorised Users or anyone else if such information is not kept confidential by the Customer or its Authorised Users, or if such information is correctly provided by an unauthorised third party logging in to and accessing the Services.</p>
			<p>The limitations under this ‘Limitation of liability’ section apply with respect to all legal theories, whether in contract, tort or otherwise, and to the extent permitted by law. The provisions of this ‘Limitation of liability’ section allocate the risks under this Contract between the parties, and the parties have relied on these limitations in determining whether to enter into this Contract and the pricing for the Services.</p>
			<h2>Our indemnification of the Customer</h2>
			<p>We will defend the Customer from and against any and all third-party claims, actions, suits, proceedings and demands alleging that the use of the Services as permitted under the Contract infringes or misappropriates a third party’s intellectual property rights (a <b>‘Claim Against Customer’</b>), and will indemnify the Customer for all reasonable solicitor’s fees incurred and damages and other costs finally awarded against the Customer in connection with or as a result of, and for amounts paid by the Customer under a settlement that we approve of in connection with, a Claim Against Customer; provided, however, that we will have no liability if a Claim Against Customer arises from (a) Customer Data; and (b) any modification, combination or development of the Services that is not performed by us, including in the use of any application programming interface (API). The Customer must provide us with prompt written notice of any Claim Against Customer and allow us the right to assume the exclusive defence and control, and cooperate with any reasonable requests assisting our defence and settlement of such a matter. This section states our sole liability with respect to, and the Customer’s exclusive remedy against us and the Exploo Extended Family for, any Claim Against Customer.</p>
			<h2>The Customer’s indemnification of us</h2>
			<p>The Customer will defend Exploo and the members of the Exploo Extended Family (collectively, the <b>‘Exploo Indemnified Parties’</b>) from and against any and all third-party claims, actions, suits, proceedings and demands arising from or related to the Customer’s or any of its Authorised Users’ violation of the Contract or the User Terms (a <b>‘Claim Against Us’</b>), and will indemnify the Exploo Indemnified Parties for all reasonable solicitor’s fees incurred and damages and other costs finally awarded against a Exploo Indemnified Party in connection with or as a result of, and for amounts paid by a Exploo Indemnified Party under a settlement that the Customer approves of in connection with, a Claim Against Us. We must provide the Customer with prompt written notice of any Claim Against Us and allow the Customer the right to assume the exclusive defence and control, and cooperate with any reasonable requests assisting the Customer’s defence and settlement of such a matter. This section states your sole liability with respect to, and the Exploo Indemnified Parties’ exclusive remedy against the Customer for, any Claim Against Us.</p>
			<h2>Limitation of indemnification</h2>
			<p>Notwithstanding anything contained in the two preceding sections, (a) an indemnified party will always be free to choose its own legal representation if it pays for the cost of such representation; and (b) no settlement may be entered into by an indemnifying party without the express written consent of the indemnified parties (such consent not to be unreasonably withheld), if (i) the third party asserting the claim is a government agency; (ii) the settlement arguably involves the making of admissions by the indemnified parties; (iii) the settlement does not include a full release of liability for the indemnified parties; or (iv) the settlement includes terms other than a full release of liability for the indemnified parties and the payment of money.</p>
			<h2>Confidentiality</h2>
			<p>Confidential information</p>
			<p>Each party (<b>‘Disclosing Party’</b>) may disclose <b>‘Confidential Information’</b> to the other party (<b>‘Receiving Party’</b>) in connection with the Contract, which is anything that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure including all Order Forms, as well as non-public business, product, technology and marketing information. Confidential Information of the Customer includes Customer Data. If something is labelled ‘Confidential’, that is a clear indicator to the Receiving Party that the material is confidential. Notwithstanding the above, Confidential Information does not include information that (a) is or becomes generally available to the public without breach of any obligation owed to the Disclosing Party; (b) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party; (c) is received from a third party without breach of any obligation owed to the Disclosing Party; or (d) was independently developed by the Receiving Party.</p>
			<p>Protection and use of Confidential Information</p>
			<p>The Receiving Party will (a) take at least reasonable measures to prevent the unauthorised disclosure or use of Confidential Information, and limit access to those employees, affiliates and contractors who need to know such information in connection with the Contract; and (b) not use or disclose any Confidential Information of the Disclosing Party for any purpose outside the scope of this Contract. Nothing above will prevent either party from sharing Confidential Information with financial and legal advisors; provided, however, that the advisors are bound to confidentiality obligations at least as restrictive as those in the Contract.</p>
			<p>Compelled access or disclosure</p>
			<p>The Receiving Party may access or disclose Confidential Information of the Disclosing Party if it is required by law; provided, however, that the Receiving Party gives the Disclosing Party prior notice of the compelled access or disclosure (to the extent legally permitted) and reasonable assistance, at the Disclosing Party's cost, if the Disclosing Party wishes to contest the access or disclosure. If the Receiving Party is compelled by law to access or disclose the Disclosing Party’s Confidential Information, the Disclosing Party will reimburse the Receiving Party for its reasonable cost of compiling and providing access to such Confidential Information, as well as the reasonable cost for any support provided in connection with the Disclosing Party seeking a protective order or confidential treatment for the Confidential Information to be produced.</p>
			<h2>Survival</h2>
			<p>The sections titled ‘We welcome feedback’, ‘Our removal rights’, ‘Use of the Services’, ‘Payment terms’, ‘Credits’, ‘The Exploo Extended Family’, ‘What’s yours is yours...’, ‘And what’s ours is ours’, ‘Effect of termination’, ‘Data portability and deletion’, ‘Representations, disclaimer of warranties’, ‘Limitation of liability’, ‘Our indemnification of the Customer’, ‘The Customer’s indemnification of us’, ‘Limitations on indemnifications’, ‘Confidentiality’ and ‘Survival’, as well as all of the provisions under the general heading ‘General provisions’, will survive any termination or expiration of the Contract.</p>
			<h2>General provisions</h2>
			<p>Publicity</p>
			<p>The Customer grants us the right to use the Customer’s company name and logo as a reference for marketing or promotional purposes on our website and in other public or private communications with our existing or potential customers, subject to the Customer’s standard trademark usage guidelines as provided to us from time to time. We don’t want to list customers who don’t want to be listed, so the Customer may send us an email to feedback@exploo.com stating that it does not wish to be used as a reference.</p>
			<p>Force majeure</p>
			<p>Neither we nor the Customer will be liable by reason of any failure or delay in the performance of its obligations on account of events beyond the reasonable control of a party, which may include denial-of-service attacks, a failure by a third-party hosting provider or utility provider, strikes, shortages, riots, fires, acts of God, war, terrorism and government action.</p>
			<p>Relationship of the parties, no third-party beneficiaries</p>
			<p>The parties are independent contractors. The Contract does not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship between the parties. There are no third-party beneficiaries to the Contract.</p>
			<p>Emails and Exploo messages</p>
			<p>Except as otherwise set forth herein, all notices under the Contract will be by email, although we may instead choose to provide notice to the Customer through the Services (e.g. notifications). Notices to Exploo will be sent to feedback@exploo.com, except for legal notices, such as notices of termination or an indemnifiable claim, which must be sent to legal@exploo.com. Notices will be deemed to have been duly given (a) the day after they are sent, in the case of notices through email; and (b) the same day, in the case of notices through the Services.</p>
			<p>Modifications</p>
			<p>As our business evolves, we may change these Customer Terms and the other components of the Contract (except any Order Forms). If we make a material change to the Contract, we will provide the Customer with reasonable notice prior to the change taking effect, either by emailing the email address associated with the Customer’s account or by messaging the Customer through the Services. The Customer can review the most current version of the Customer Terms at any time by visiting this page and by visiting the most current versions of the other pages that are referenced in the Contract. The materially revised Contract will become effective on the date set forth in our notice, and all other changes will become effective upon posting of the change. If the Customer (or any Authorised User) accesses or uses the Services after the effective date, that use will constitute the Customer’s acceptance of any revised terms and conditions.</p>
			<p>Waiver</p>
			<p>No failure or delay by either party in exercising any right under the Contract will constitute a waiver of that right. No waiver under the Contract will be effective unless made in writing and signed by an authorised representative of the party being deemed to have granted the waiver.</p>
			<p>Severability</p>
			<p>The Contract will be enforced to the fullest extent permitted under applicable law. If any provision of the Contract is held by a court of competent jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the Contract will remain in effect.</p>
			<p>Assignment</p>
			<p>Except with respect to the Exploo Extended Family, neither party may assign or delegate any of its rights or obligations hereunder, whether by operation of law or otherwise, without the prior written consent of the other party (not to be unreasonably withheld). Notwithstanding the foregoing, either party may assign the Contract in its entirety (including all Order Forms), without consent of the other party, to a corporate affiliate or in connection with a merger, acquisition, corporate reorganisation or the sale of all, or substantially all, of its assets. The Customer will keep its billing and contact information current at all times by notifying Exploo of any changes. Any purported assignment in violation of this section is void. A party’s sole remedy for any purported assignment by the other party in breach of this section will be, at the non-assigning party’s election, termination of the Contract upon written notice to the assigning party. In the event of such a termination by the Customer, we will refund the Customer any prepaid fees covering the remainder of the term of all subscriptions after the effective date of termination. Subject to the foregoing, the Contract will bind and inure to the benefit of the parties, their respective successors and permitted assigns.</p>
			<p>Which Exploo entity is the Customer contracting with?</p>
			<p>All references to Exploo, ‘we’ or ‘us’ under the Contract, what law will apply in any dispute or lawsuit arising out of or in connection with the Contract and which courts have jurisdiction over any such dispute or lawsuit depends on where the Customer is domiciled.</p>
			<p>The Contract, and any disputes arising out of or related hereto, will be governed exclusively by the applicable governing law above, without regard to conflicts of laws, rules or the United Nations Convention on the International Sale of Goods. The courts located in the applicable venue above will have exclusive jurisdiction to adjudicate any dispute arising out of or relating to the Contract or its formation, interpretation or enforcement. Each party hereby consents and submits to the exclusive jurisdiction of such courts. In any action or proceeding to enforce rights under the Contract, the prevailing party will be entitled to recover its reasonable costs and solicitor’s fees.</p>
			<p>Entire agreement</p>
			<p>The Contract, including these Customer Terms and all referenced pages and Order Forms, if applicable, constitutes the entire agreement between the parties and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. Without limiting the foregoing, the Contract supersedes the terms of any online agreement electronically accepted by the Customer or any Authorised Users. However, to the extent of any conflict or inconsistency between the provisions in these Customer Terms and any other documents or pages referenced in these Customer Terms, the following order of precedence will apply: (1) the terms of any Order Form (if any), (2) the portions of a Customer-Specific Supplement that apply to the Customer (if any), (3) the Customer Terms and (4) finally any other documents or pages referenced in the Customer Terms. Notwithstanding any language to the contrary therein, no terms or conditions stated in a Customer purchase order, vendor onboarding process or web portal, or any other Customer order documentation (excluding Order Forms) will be incorporated into or form any part of the Contract, and all such terms or conditions will be null and void.</p>
			<p></p>
			<p></p>
		</TermsLayout>
	)
}

export default Terms